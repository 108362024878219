import '@css/main.scss'
import '@js/bootstrap'

import {CustomMarker} from "@js/components/CustomMarker";
import {Lightbox} from "@js/components/LightBox";

window.addEventListener('DOMContentLoaded', () => {
  new CustomMarker()
  new Lightbox()
})


