export class CustomMarker {
  constructor() {
    this._li = document.querySelectorAll('.s-content--style-13 .s-content ul li')
    this._tabli = document.querySelectorAll('.s-content--style-12 .s-content-layout ul li')
    this._tabli16 = document.querySelectorAll('.s-content--style-16 .s-content-layout .s-content-layout-inner p')
    console.log(this._tabli)

    if (this._li.length) {
      this.li();
    }

    if (this._tabli.length) {
      this.tabli();
    }

    if (this._tabli16.length) {
      this.tabli16();
    }
  }

  li() {
    this._li.forEach((item) => {
      this.appendArrow(item);
    })
  }

  tabli() {
    this._tabli.forEach((item) => {
      this.appendArrow(item);
    })
  }

  tabli16() {
    this._tabli16.forEach((item) => {
      this.appendArrow(item);
    })
  }

  appendArrow(item) {
    let el = document.createElement('div');
    el.classList.add('marker-icon')

    el.innerHTML = `<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
       <g id="Frame">
       <path id="Vector"
     d="M4.67495 1.5C5.26495 1.415 5.88995 2.06 6.06995 2.95C6.24995 3.835 5.92495 4.625 5.33495 4.715C4.74995 4.805 4.11995 4.16 3.93495 3.27C3.74995 2.385 4.08495 1.595 4.67495 1.5ZM8.24995 1.5C8.84495 1.595 9.17495 2.385 8.99995 3.27C8.80995 4.16 8.18495 4.805 7.59495 4.715C6.99995 4.625 6.67495 3.835 6.85995 2.95C7.03995 2.06 7.66495 1.415 8.24995 1.5ZM1.99995 3.8C2.56995 3.555 3.34495 4 3.74995 4.775C4.12995 5.565 3.99995 6.395 3.43495 6.64C2.86995 6.885 2.09995 6.445 1.70495 5.66C1.30995 4.875 1.44995 4.04 1.99995 3.8ZM11 3.8C11.55 4.04 11.69 4.875 11.295 5.66C10.9 6.445 10.13 6.885 9.56495 6.64C8.99995 6.395 8.86995 5.565 9.24995 4.775C9.65495 4 10.43 3.555 11 3.8ZM10.165 9.19C10.185 9.66 9.82495 10.18 9.39495 10.375C8.49995 10.785 7.43995 9.935 6.44495 9.935C5.44995 9.935 4.37995 10.82 3.49995 10.375C2.99995 10.13 2.65495 9.48 2.71995 8.94C2.80995 8.195 3.70495 7.795 4.23495 7.25C4.93995 6.545 5.43995 5.22 6.44495 5.22C7.44495 5.22 7.97495 6.525 8.64995 7.25C9.20495 7.86 10.13 8.375 10.165 9.19Z"
     fill="#FAFAFA"/>
       </g>
   </svg>`;


    item.appendChild(el);
  }
}
